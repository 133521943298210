import React from "react";
import styles from "../styles/Footer.module.css";
import Link from "next/link";

type MainFooterItemProps = {
  title: string;
  items: DetailMainFooterItemProps[];
};

type DetailMainFooterItemProps = {
  href?: string;
  target: string;
  text: string;
  subItems?: DetailMainFooterItemProps[];
};

const MainFooterItem = (props: MainFooterItemProps) => {
  const renderItem = (item: DetailMainFooterItemProps, idx: number) => (
    <div key={`footer-item-${idx}`}>
      {item.href ? (
        <Link href={item.href}>
          <a
            className={styles.footer_item_text}
            target={item.target || undefined}
            rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
          >
            {item.text}
          </a>
        </Link>
      ) : (
        <p className={styles.footer_item_text}>{item.text}</p>
      )}
      {item.subItems && (
        <ul className={styles.footer_sub_item_list}>
          {item.subItems.map((subItem, subIdx) => (
            <li
              key={`footer-item-${idx}-sub-${subIdx}`}
              className={styles.footer_sub_item}
            >
              {renderItem(subItem, subIdx)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
  return (
    <>
      <div className={styles.footer_item_wrapper}>
        <p className={styles.footer_item_title}>{props.title}</p>
        <div
          className={`${styles.footer_item_content} ${
            props.title === "Regulatory Compliance System"
              ? styles.special_style
              : ""
          }`}
        >
          {props.items.map((item, idx) => renderItem(item, idx))}
        </div>
      </div>
    </>
  );
};

export default MainFooterItem;
