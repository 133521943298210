export interface UTMData {
  utmSource?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmKeywords?: string;
}

export function storeUTMParams(): void {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const medium = params.get("utm_medium") as string;
    const source = params.get("utm_source") as string;
    const utmParams: UTMData = {
      utmSource: medium || source ? `${medium || ""} - ${source || ""}` : "-",
      utmContent: params.get("utm_content") as string,
      utmCampaign: params.get("utm_campaign") as string,
      utmKeywords: params.get("utm_term") as string,
    };

    sessionStorage.setItem("utmData", JSON.stringify(utmParams));
  }
}

export function getEmptyUTMParams(): UTMData {
  return {
    utmSource: "-",
    utmCampaign: "-",
    utmContent: "-",
    utmKeywords: "-",
  };
}

export function getUTMParams(): UTMData {
  if (typeof window !== "undefined") {
    const utmData = sessionStorage.getItem("utmData");
    if (utmData) {
      return JSON.parse(utmData) as UTMData;
    }
  }
  return getEmptyUTMParams();
}

export function initializeUTMTracking(): void {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);

    const hasUTM =
      params.has("utm_medium") ||
      params.has("utm_source") ||
      params.has("utm_campaign") ||
      params.has("utm_content") ||
      params.has("utm_term");

    if (hasUTM) {
      storeUTMParams();
    }
  }
}
