import React from "react";
import styles from "styles/main-activity/Icon.module.css";

const Copyright = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.icon_fg_copyRight}
        d="M7.99122 0C3.57616 0 -0.00292969 3.58171 -0.00292969 8C-0.00292969 12.4183 3.57616 16 7.99122 16C12.4063 16 15.9854 12.4183 15.9854 8C15.9854 3.58171 12.4063 0 7.99122 0ZM11.767 11.1856C11.7157 11.2458 10.4848 12.6608 8.22598 12.6608C5.49598 12.6608 3.56862 10.6201 3.56862 7.96506C3.56862 5.34239 5.56728 3.33923 8.20271 3.33923C10.361 3.33923 11.4895 4.54294 11.5365 4.59419C11.5943 4.65732 11.6296 4.73787 11.6369 4.82323C11.6441 4.90859 11.6228 4.99394 11.5764 5.0659L10.855 6.18381C10.7244 6.38597 10.4432 6.42094 10.2672 6.25784C10.2597 6.25094 9.41205 5.48752 8.27253 5.48752C6.786 5.48752 5.88989 6.57058 5.88989 7.94177C5.88989 9.21926 6.71232 10.5125 8.28417 10.5125C9.53154 10.5125 10.3884 9.59835 10.3969 9.5891C10.5624 9.40958 10.8501 9.42674 10.9935 9.62306L11.7848 10.706C11.8362 10.7763 11.8623 10.8619 11.8591 10.9489C11.8559 11.0359 11.8234 11.1193 11.767 11.1856Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Copyright;
