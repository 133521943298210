import React from "react";
import styles from "../styles/Footer.module.css";

type MainFooterContactItemProps = {
  icon: React.ReactNode;
  items: DetailMainFooterItemsProps[];
};

type DetailMainFooterItemsProps = {
  title: string;
  action?: () => void;
};

const MainFooterContactItem = (props: MainFooterContactItemProps) => {
  return (
    <div className={styles.hol_contact_item}>
      <div className={styles.hol_contact_icon}>{props.icon}</div>
      <div className={styles.hol_contact_item_wrapper}>
        {props.items.map((item, idx) => (
          <p
            key={`footer-contact-item-${idx}`}
            className={styles.hol_contact_item_title}
            onClick={item.action}
          >
            {item.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default MainFooterContactItem;
