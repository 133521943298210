import React from "react";
import styles from "styles/main-activity/Icon.module.css";
const LocationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9878_3327)">
        <path
          className={styles.icon_fg_location}
          d="M7.85275 1.25C5.32023 1.25 3.27197 3.20625 3.27197 5.625C3.27197 8.90625 7.85275 13.75 7.85275 13.75C7.85275 13.75 12.4335 8.90625 12.4335 5.625C12.4335 3.20625 10.3853 1.25 7.85275 1.25ZM7.85275 7.1875C6.94968 7.1875 6.21676 6.4875 6.21676 5.625C6.21676 4.7625 6.94968 4.0625 7.85275 4.0625C8.75582 4.0625 9.48874 4.7625 9.48874 5.625C9.48874 6.4875 8.75582 7.1875 7.85275 7.1875Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9878_3327">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
