import demoStr from "@assets/strings/request_demo.json";
import homeStr from "@assets/strings/home.json";
import { MouseEventHandler } from "react";
import { Languages } from "utils/datautils";
import styles from "../styles/RequestDemo.module.scss";
import Link from "next/link";

type ReqDemoProps = {
  lang: Languages;
  onModalControl: MouseEventHandler<HTMLButtonElement>;
  requestDemoClassType: string;
};
/**
 *
 * @param lang to switch between languages
 * @returns
 * @param onModalControl to notify modal control event to show up the modal
 */
export default function RequestDemo({
  lang,
  onModalControl,
  requestDemoClassType,
}: ReqDemoProps) {
  const renderText = () => {
    const renderer = (
      <>
        <span>Kami memahami bisnis dan tantangan Anda.</span> Dapatkan solusi
        terbaik bagi kepatuhan hukum perusahaan Anda sekarang.
      </>
    );
    return renderer;
  };

  return (
    <div
      className={styles.request_section}
      onClick={(click) => {
        click.preventDefault();
        click.stopPropagation();
        return onModalControl;
      }}
    >
      <div>
        <p
          className={styles.request_section_subtitle}
          dangerouslySetInnerHTML={{ __html: demoStr.subtitle[lang] }}
        ></p>
        <Link href={lang === "id" ? "/form-trial" : "/en/form-trial"}>
          <button
            className={`${styles.request_section_btn} ${styles.mobile} ${requestDemoClassType}`}
          >
            {homeStr.trial[lang]}
          </button>
        </Link>
        <Link href={lang === "id" ? "/form-trial" : "/en/form-trial"}>
          <button
            className={`${styles.request_section_btn} ${styles.web} ${requestDemoClassType}`}
          >
            {homeStr.trial[lang]}
          </button>
        </Link>
      </div>
    </div>
  );
}
