import React, { useState } from "react";

import styles from "../styles/Navbar.module.css";
import { SolutionType } from "./NavbarContext";
import Link from "next/link";

type SolutionItemNavbarProps = {
  item: SolutionType;
};

const SolutionItemNavbar = (props: SolutionItemNavbarProps) => {
  const [itemActivityHover, setItemActivityHover] = useState(false);
  return (
    <Link href={props.item.path}>
      <a
        className={styles.solutions_dropdown_item}
        onMouseEnter={() => setItemActivityHover(true)}
        onMouseLeave={() => setItemActivityHover(false)}
      >
        <div className={`${styles.solutions_icon}`}>
          <img
            src={
              itemActivityHover
                ? props.item.activity_icon_hover
                : props.item.activity_icon
            }
            alt="Icon"
            className={styles.solutions_icon_image}
          />
        </div>

        <div className={`${styles.solutions_activity_name}`}>
          {props.item.activity_name}
        </div>
      </a>
    </Link>
  );
};

export default SolutionItemNavbar;
