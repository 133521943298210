import Link from "next/link";
import { useEffect, useState } from "react";
import navStr from "@assets/strings/navbar.json";
import { Languages } from "../utils/datautils";

import styles from "../styles/Navbar.module.css";
import { useRouter } from "next/router";
import getMainActivity from "services/mainActivity";
import { useNavbar } from "./NavbarContext";
import IconUpstreamAndGas from "./icon/IconUpstreamAndGas";
import CaretDown from "./icon/CaretDown";
import CaretDownFill from "./icon/CaretDownFIll";
import SolutionItemNavbar from "./SolutionItemNavbar";

type MainProps = {
  lang: Languages;
  onLangChange: Function;
  onRequestDemo: Function;
};

export default function MainNavbar(props: MainProps) {
  const { solutions } = useNavbar();
  const [itemActivityHover, setItemActivityHover] = useState(false);
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [requestDisplay, setRequestDisplay] = useState(true);
  const [aboutDisplay, setAboutDisplay] = useState(false);
  const [langDisplay, setLangDisplay] = useState(false);
  const [solutionDropdownDisplay, setSolutionDropdownDisplay] = useState(false);
  /** to do consider dispatcher */
  const [language, setLanguage] = useState(Languages.Bahasa);
  const [languageList] = useState([
    {
      name: "EN",
      value: Languages.English,
    },
    {
      name: "ID",
      value: Languages.Bahasa,
    },
  ]);

  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  useEffect(() => {
    props.onLangChange(language);
  }, [language]);

  useEffect(() => {
    locale === "id"
      ? setLanguage(Languages.Bahasa)
      : setLanguage(Languages.English);
    locale === "id" ? handleChangeLanguage("id") : handleChangeLanguage("en");
  }, [locale]);

  const showMenu = () => {
    setMenuDisplay(!menuDisplay);
    setRequestDisplay(!requestDisplay);
  };

  const handleChangeLanguage = async (lang: string) => {
    try {
      setLangDisplay(false);
      lang == "id"
        ? setLanguage(Languages.Bahasa)
        : setLanguage(Languages.English);
      router.push({ pathname, query }, asPath, { locale: lang });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSolutionDropdownDisplay = () => {
    setSolutionDropdownDisplay(!solutionDropdownDisplay);
  };

  useEffect(() => {
    if (menuDisplay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuDisplay]);

  const typedNavStr: Record<string, Record<string, string>> = navStr;

  return (
    <>
      <nav id="navigation-top-section" className={styles.top_section}>
        <div className={` ${styles.top_section_wrapper} container`}>
          {/* Lang Button */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
            onClick={() => setLangDisplay(!langDisplay)}
            className={styles.switch_language_wrapper}
          >
            <div className={styles.switch_language_container}>
              {/* <img alt="navigation globe" src="/assets/globe.svg" onClick={() => setLangDisplay(!langDisplay)} className={styles.global_icon} /> */}
              {langDisplay ? (
                <>
                  {/* <div className={styles.triangle_up}></div> */}
                  <span className={styles.language_list__box}>
                    <div
                      className={styles.language}
                      onClick={() => {
                        if (
                          window.location.pathname.includes("/insights/") ||
                          window.location.pathname.includes("/use-case/")
                        ) {
                          router.push("/", "", { locale: "id" });
                          setLangDisplay(false);
                        } else {
                          handleChangeLanguage("id");
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                      >
                        {/* <img
                          src="/assets/bahasa_icon.png"
                          style={{
                            width: "20px",
                            height: "auto",
                            objectFit: "contain",
                            marginLeft: 0,
                          }}
                        /> */}
                        <a
                          style={{
                            fontWeight:
                              language === Languages.Bahasa ? "600" : "",
                            color:
                              language === Languages.Bahasa
                                ? "#0177CC"
                                : "#003366",
                            textDecoration: "none",
                          }}
                        >
                          (ID) Indonesia
                        </a>
                      </div>
                    </div>
                    <div className={styles.divider}></div>
                    <div
                      className={styles.language}
                      style={{ marginTop: "8px" }}
                      onClick={() => {
                        if (
                          window.location.pathname.includes("/insights/") ||
                          window.location.pathname.includes("/use-case/")
                        ) {
                          router.push("/", "", { locale: "en" });
                          setLangDisplay(false);
                        } else {
                          handleChangeLanguage("en");
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                      >
                        {/* <img
                          src="/assets/english_icon.png"
                          style={{
                            width: "20px",
                            height: "auto",
                            objectFit: "contain",
                            marginLeft: 0,
                          }}
                        /> */}
                        <a
                          style={{
                            fontWeight:
                              language !== Languages.Bahasa ? "600" : "",
                            color:
                              language !== Languages.Bahasa
                                ? "#0177CC"
                                : "#003366",
                            textDecoration: "none",
                          }}
                        >
                          (EN) English
                        </a>
                      </div>
                      󠁧󠁢
                    </div>
                  </span>
                </>
              ) : (
                ""
              )}
            </div>

            {language === Languages.Bahasa ? (
              <p className={styles.lang_nav_display}>ID</p>
            ) : (
              <p className={styles.lang_nav_display}>EN</p>
            )}
            <div className={styles.icon_down}>
              <CaretDownFill />
            </div>
          </div>
          {/* <Link href={"/"}>
            <a className={styles.top_section_text}>Login</a>
          </Link> */}
        </div>
      </nav>
      <nav id="navigation-logo" className={styles.site_nav}>
        <div className="grid container">
          <Link href="/">
            <a>
              <img
                alt="main logo"
                className={styles.nav_logo}
                src="/assets/rcs_logo.png"
                style={{ objectFit: "contain" }}
              />
            </a>
          </Link>

          {/* Mobile Version start*/}
          <div className={`${styles.nav_menu} flex`}>
            {/* {requestDisplay ? (
              <button
                className={`${styles.nav_demo}`}
                onClick={() => props.onRequestDemo()}
              >
                {navStr.contact[language]}
              </button>
            ) : (
              ""
            )} */}

            <div
              className={styles.spannable + styles.min}
              style={{ paddingLeft: "13px" }}
            >
              <img
                alt="navigation menu"
                src={
                  menuDisplay
                    ? "/assets/close.svg"
                    : "/assets/menu_navigation_icon.svg"
                }
                onClick={() => {
                  showMenu();
                }}
              />
            </div>
          </div>

          {menuDisplay ? (
            <span className={styles.dropdown_full}>
              <Link href="/">
                <a className={styles.nav_title} onClick={showMenu}>
                  {navStr.home[language]}
                </a>
              </Link>

              <Link href="/about">
                <a className={styles.nav_title} onClick={showMenu}>
                  {navStr.aboutRcs[language]}
                </a>
              </Link>

              <a
                className={styles.nav_title}
                style={{
                  borderBottom: solutionDropdownDisplay
                    ? "2px solid #ffffff"
                    : "none",
                  textDecoration: "none",
                  paddingBottom: "8px",
                }}
                onClick={toggleSolutionDropdownDisplay}
              >
                {navStr.solutions[language]}
              </a>

              {/* Dropdown Solutions Mobile */}
              {solutionDropdownDisplay && (
                <div className={styles.solution_dropdown_mobile}>
                  {solutions.map((item, index) => (
                    <Link key={index} href={item.path}>
                      <a
                        className={styles.solutions_dropdown_item_mobile}
                        onClick={showMenu}
                      >
                        <div className={`${styles.solutions_icon_mobile}`}>
                          <img
                            src={item.activity_icon}
                            alt="Icon"
                            className={styles.solutions_icon_image_mobile}
                          />
                        </div>
                        <div
                          className={`${styles.solutions_activity_name_mobile}`}
                        >
                          {item.activity_name}
                        </div>
                      </a>
                    </Link>
                  ))}
                </div>
              )}

              <Link href="/use-case">
                <a className={styles.nav_title} onClick={showMenu}>
                  {navStr.useCase[language]}
                </a>
              </Link>

              <Link href="/insights">
                <a className={styles.nav_title} onClick={showMenu}>
                  {navStr.articles[language]}
                </a>
              </Link>

              <Link href="/faq">
                <a className={styles.nav_title} onClick={showMenu}>
                  {navStr.faqs[language]}
                </a>
              </Link>

              {/* Button Lang Mobile */}
              <div className={styles.site_nav_language}>
                {languageList.map((lang, index) => {
                  return (
                    <span
                      key={index}
                      style={{
                        borderTopLeftRadius: index === 0 ? "10px" : "0px",
                        borderBottomLeftRadius: index === 0 ? "10px" : "0px",
                        borderTopRightRadius:
                          index === languageList.length - 1 ? "10px" : "0px",
                        borderBottomRightRadius:
                          index === languageList.length - 1 ? "10px" : "0px",
                        backgroundColor:
                          language === lang.value ? "#F8CA0F" : "#FFF",
                        color: language === lang.value ? "#003366" : "#777",
                        padding: 10,
                        cursor: "pointer",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        if (
                          window.location.pathname.includes("/insights/") ||
                          window.location.pathname.includes("/use-case/")
                        ) {
                          router.push("/", "", {
                            locale:
                              lang.name.toLocaleLowerCase() === "id"
                                ? "id"
                                : "en",
                          });
                        } else {
                          handleChangeLanguage(
                            lang.name.toLocaleLowerCase() === "id" ? "id" : "en"
                          );
                        }
                      }}
                    >
                      {lang.name}
                    </span>
                  );
                })}
              </div>
              <a
                href={
                  language === Languages.English
                    ? "/en/form-trial"
                    : "/form-trial"
                }
              >
                <button
                  className="free"
                  style={{
                    display: "flex",
                    position: "static",
                    height: "40px",
                  }}
                >
                  {navStr.requestDemo[language]}
                </button>
              </a>
              {/* <a>
                <button
                  className={`free ${styles.nav_contact_button} `}
                  onClick={() => {
                    props.onRequestDemo();
                    showMenu();
                  }}
                  style={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    position: "static",
                  }}
                >
                  {navStr.contact[language]}
                </button>
              </a> */}
            </span>
          ) : (
            ""
          )}
          {/* Mobile Version end*/}

          {/* Web Version start*/}
          <ul className={styles.nav_desktop}>
            <div className={styles.nav_desktop_wrapper}>
              <li
                className={`${styles.spannable} ${styles.nav_list_box} ${styles.nav_fit_content}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAboutDisplay(!aboutDisplay);
                }}
                style={{ minWidth: "0px" }}
              >
                <Link href="/about">
                  <a className={styles.nav_title}>
                    {navStr.aboutRcs[language]}
                  </a>
                </Link>
              </li>

              <li className={styles.nav_list_box}>
                <div className={styles.dropdown_container}>
                  <div className={`${styles.nav_title} ${styles.flex_center}`}>
                    {navStr.solutions[language]}
                    <CaretDown />
                  </div>
                  <div className={`${styles.solutions_dropdown}`}>
                    <div className={styles.solutions_title}>
                      {navStr.solutionDropdownMenuTitle[language]}
                    </div>
                    <div className={styles.solutions_dropdown_wrapper}>
                      {solutions.map((item, index) => (
                        <SolutionItemNavbar key={index} item={item} />
                      ))}
                    </div>
                  </div>
                </div>
              </li>

              <Link href="/use-case">
                <a className={styles.nav_list_box} style={{ minWidth: "0px" }}>
                  <li className={styles.nav_title}>
                    {navStr.useCase[language]}
                  </li>
                </a>
              </Link>

              <Link href="/insights">
                <a className={styles.nav_list_box}>
                  <li className={styles.nav_title}>
                    {navStr.articles[language]}
                  </li>
                </a>
              </Link>

              <Link href="/faq">
                <a
                  className={`${styles.nav_list_box}`}
                  style={{
                    minWidth: "0px",
                  }}
                >
                  <li className={styles.nav_title}>{navStr.faqs[language]}</li>
                </a>
              </Link>
            </div>

            <li className={styles.custom_list_container}>
              <div className={styles.button_section}>
                {/* <button
                  className={styles.nav_contact_button}
                  onClick={() => props.onRequestDemo()}
                >
                  {navStr.contact[language]}
                </button> */}
                <Link
                  href={
                    language === Languages.English
                      ? "/en/form-trial"
                      : "/form-trial"
                  }
                >
                  <button className={styles.nav_free_trial_button}>
                    {navStr.requestDemo[language]}
                  </button>
                </Link>
              </div>
            </li>
          </ul>

          {/* Web Version end*/}
        </div>
      </nav>
    </>
  );
}
