import React, { useEffect, useRef, useState } from "react";
import styles from "styles/Home.module.scss";
import { Languages } from "utils/datautils";
import homeStr from "@assets/strings/home.json";
import { banner } from "utils/banner";
import Link from "next/link";

type BannerProps = {
  banner: banner[];
  setBannerSwipePos: Function;
  calculateBannerSwipe: Function;
  modalControl: Function;
  page: number;
  backPage: Function;
  nextPage: Function;
  lang: Languages;
  setPage: Function;
};

export default function BannerSlider({
  backPage,
  banner,
  calculateBannerSwipe,
  lang,
  modalControl,
  nextPage,
  page,
  setBannerSwipePos,
  setPage,
}: BannerProps) {
  const [screenWidth, setScreenWidth] = useState(0);
  const heroContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
      window.addEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    }
  }, []);

  return (
    <>
      {banner[page].attributes?.image_mobile?.data?.attributes?.url &&
      screenWidth < 900 ? (
        <div style={{ position: "relative" }}>
          <img
            src={banner[page]?.attributes?.image_mobile?.data?.attributes?.url}
            className={styles.hero_banner_mobile}
          />

          <div className={styles.hero_banner_text_mobile}>
            <div className={`${styles.hero_desc} ${styles.flexColCenter}`}>
              <div ref={heroContent} className={`${styles.hero_text}`}>
                {banner[page]?.attributes?.description && (
                  // <p className="subheader">{banner[page]?.attributes?.title}</p>
                  <h3 className={`${styles.hero_title}`}>
                    {banner[page]?.attributes?.title}
                  </h3>
                )}
                {banner[page]?.attributes?.description && screenWidth > 991 && (
                  <p
                    className={styles.hero_subtitle}
                    dangerouslySetInnerHTML={{
                      __html: banner[page]?.attributes?.description,
                    }}
                  ></p>
                )}
              </div>

              {!banner[page]?.attributes?.hide_button && (
                <div className={`${styles.btn_demo_container}`}>
                  <div className={`flex ${styles.hero_menu}`}>
                    <Link
                      href={lang === "id" ? "/form-trial" : "/en/form-trial"}
                    >
                      <button
                        className="free gtm-requestDemo-hero-homepage"
                        style={{ zIndex: "100" }}
                      >
                        {homeStr.trial[lang]}
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!banner[page]?.attributes?.hide_navigation && (
            <div className={styles.nav_container}>
              <div className={`${styles.scnd_layer_arrow_container}`}>
                {page !== 0 ? (
                  <img
                    alt="arrow-back.png"
                    className={styles.hero_back_mobile}
                    src="/assets/arrow-left-circle.png"
                    onClick={() => backPage()}
                  ></img>
                ) : (
                  <div className={`${styles.hero_back}`}></div>
                )}

                {page !== banner.length - 1 ? (
                  <img
                    alt="arrow-next.png"
                    className={styles.hero_next_mobile}
                    src="/assets/arrow-right-circle.png"
                    onClick={() => nextPage()}
                  ></img>
                ) : (
                  <div className={`${styles.hero_next}`}></div>
                )}
              </div>
            </div>
          )}

          {!banner[page]?.attributes?.hide_navigation && (
            <div className={styles.bullets_mobile}>
              {banner.map((ctn: banner, idx: number) => {
                return (
                  <div
                    key={`bullet-${idx}`}
                    className={`${styles.bullet} ${
                      idx === page ? styles.active : ""
                    }`}
                    onClick={() => setPage(idx)}
                  ></div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div
          onTouchStart={(e) => setBannerSwipePos(e.changedTouches[0].screenX)}
          onTouchEnd={(e) => calculateBannerSwipe(e.changedTouches[0].screenX)}
          className={`flex ${
            banner[page]?.attributes?.image_mobile?.data?.attributes &&
            screenWidth < 700
              ? styles.hero_background_mobile
              : styles.hero_background
          }`}
          style={{
            background: `url(${banner[page]?.attributes?.image_desktop?.data?.attributes?.url})`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* {renderBannerContent(page)} */}
          <div className={`container ${styles.hero} ${styles.flexColCenter}`}>
            <div className={`${styles.hero_desc} ${styles.flexColCenter}`}>
              <div ref={heroContent} className={`${styles.hero_text}`}>
                {banner[page]?.attributes?.description && (
                  <h3 className={`${styles.hero_title}`}>
                    {banner[page]?.attributes?.title}
                  </h3>
                )}
                {banner[page]?.attributes?.description && screenWidth > 991 && (
                  <p
                    className={styles.hero_subtitle}
                    dangerouslySetInnerHTML={{
                      __html: banner[page]?.attributes?.description,
                    }}
                  ></p>
                )}
              </div>

              {!banner[page]?.attributes?.hide_button && (
                <div className={`${styles.btn_demo_container}`}>
                  <div className={`flex ${styles.hero_menu}`}>
                    <Link
                      href={lang === "id" ? "/form-trial" : "/en/form-trial"}
                    >
                      <button
                        className="free subheader_2 gtm-requestDemo-hero-homepage"
                        style={{ zIndex: "100" }}
                      >
                        {homeStr.trial[lang]}
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!banner[page]?.attributes?.hide_navigation && (
            <div className={`${styles.main_layer_arrow_container}`}>
              <div className={`${styles.scnd_layer_arrow_container}`}>
                {page !== 0 ? (
                  <img
                    alt="arrow-back.png"
                    className={`${styles.hero_back} ${
                      page === 0 ? "none" : ""
                    }`}
                    src={
                      screenWidth && screenWidth > 990
                        ? "/assets/arrow-left.png"
                        : "/assets/arrow-left-circle.png"
                    }
                    onClick={() => backPage()}
                  ></img>
                ) : (
                  <div className={`${styles.hero_back}`}></div>
                )}

                {page !== banner.length - 1 ? (
                  <img
                    alt="arrow-next.png"
                    className={`${styles.hero_next} ${
                      page === banner.length - 1 ? "none" : ""
                    }`}
                    src={
                      screenWidth && screenWidth > 990
                        ? "/assets/arrow.png"
                        : "/assets/arrow-right-circle.png"
                    }
                    onClick={() => nextPage()}
                  ></img>
                ) : (
                  <div className={`${styles.hero_next}`}></div>
                )}
              </div>
            </div>
          )}

          {!banner[page]?.attributes?.hide_navigation && (
            <div className={styles.bullets}>
              {banner.map((ctn: banner, idx: number) => {
                return (
                  <div
                    key={`bullet-${idx}`}
                    className={`${styles.bullet} ${
                      idx === page ? styles.active : ""
                    }`}
                    onClick={() => setPage(idx)}
                  ></div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
