import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Languages } from "utils/datautils";
import navStr from "@assets/strings/navbar.json";
import footerStr from "@assets/strings/footer.json";
import styles from "../styles/Footer.module.css";
import MainFooterItem from "./MainFooterItem";
import { useNavbar } from "./NavbarContext";
import Copyright from "./icon/Copyright";
import MainFooterContactItem from "./MainFooterContactItem";
import LocationIcon from "./icon/LocationIcon";
import PhoneIcon from "./icon/PhoneIcon";
import EnvelopIcon from "./icon/EnvelopIcon";

type MainProps = {
  lang: Languages;
};

export default function MainFooter(props: MainProps) {
  const { solutions } = useNavbar();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const formattedSolutions = solutions.map((solution) => ({
    href: solution.path,
    target: solution.target || "_self",
    text: solution.activity_name,
  }));

  const [language, setLanguage] = useState(props.lang);
  const rcsList = [
    {
      href: "/about",
      target: "_self",
      text: navStr.aboutRcs[props.lang],
    },
    {
      href: "",
      target: "_self",
      text: navStr.solutions[props.lang],
      subItems: formattedSolutions,
    },
    {
      href: "/use-case",
      target: "_self",
      text: navStr.useCase[props.lang],
    },
    {
      href: "/insights",
      target: "_self",
      text: navStr.articles[props.lang],
    },
    {
      href: "/faq",
      target: "_self",
      text: navStr.faqs[props.lang],
    },
    {
      href: "/terms-and-conditions",
      target: "_self",
      text: navStr.toc[props.lang],
    },
  ];

  const proList = [
    {
      href: "https://pro.hukumonline.com/",
      target: "_blank",
      text: footerStr.pro_analisisHukum[props.lang],
    },
    {
      href: "https://www.hukumonline.com/pusatdata/",
      target: "_blank",
      text: footerStr.pro_pusatData[props.lang],
    },
    {
      href: "https://pro.hukumonline.com/legal-intelligence/",
      target: "_blank",
      text: footerStr.pro_legalIntelligenceUpdates[props.lang],
    },
    {
      href: "https://www.hukumonline.com/stories/",
      target: "_blank",
      text: footerStr.pro_premiumStories[props.lang],
    },
  ];

  const solutionList = [
    {
      href: "https://pro.hukumonline.com/solusi-universitas-hukum/",
      target: "_blank",
      text: footerStr.solution_university[props.lang],
    },
    {
      href: "https://exdoma.hukumonline.com/",
      target: "_blank",
      text: footerStr.solution_dms[props.lang],
    },
    {
      href: "https://www.easybiz.id/",
      target: "_blank",
      text: footerStr.solution_izinUsaha[props.lang],
    },
    {
      href: "https://www.justika.com/",
      target: "_blank",
      text: footerStr.solution_konsultasiHukum[props.lang],
    },
    {
      href: "https://business.justika.com/",
      target: "_blank",
      text: footerStr.solution_pembuatanDokumen[props.lang],
    },
    {
      href: "https://www.hukumonline.com/hukumonline-360/",
      target: "_blank",
      text: footerStr.solution_hol360[props.lang],
    },
  ];

  const legalInsightList = [
    {
      href: "https://www.hukumonline.com/klinik/",
      target: "_blank",
      text: footerStr.legalInsight_klinik[props.lang],
    },
    {
      href: "https://www.hukumonline.com/stream/regulation-roundup/",
      target: "_blank",
      text: footerStr.legalInsight_hols[props.lang],
    },

    {
      href: "https://jurnal.hukumonline.com/",
      target: "_blank",
      text: footerStr.legalInsight_jurnal[props.lang],
    },
    {
      href: "https://www.hukumonline.com/berita/",
      target: "_blank",
      text: footerStr.legalInsight_berita[props.lang],
    },
    {
      href: "https://www.datapribadi.id/",
      target: "_blank",
      text: footerStr.legalInsight_dataPribadi[props.lang],
    },
  ];

  const eventList = [
    {
      href: "https://www.hukumonline.com/events/",
      target: "_blank",
      text: footerStr.ea_events[props.lang],
    },
    {
      href: "https://awards.hukumonline.com/",
      target: "_blank",
      text: footerStr.ea_awards[props.lang],
    },
    {
      href: "https://www.hukumonline.com/online-publication",
      target: "_blank",
      text: footerStr.ea_publikasiOnline[props.lang],
    },
    {
      href: "https://learning.hukumonline.com/",
      target: "_blank",
      text: footerStr.ea_onlineCourse[props.lang],
    },
    {
      href: "https://pkpa.hukumonline.com/",
      target: "_blank",
      text: footerStr.ea_pkpa[props.lang],
    },
  ];

  const holContactLocation = [
    {
      title:
        "AD Premier 9th floor, Jl. TB Simatupang No.5 Ragunan, Pasar Minggu, Jakarta Selatan  12550, DKI Jakarta, Indonesia",
      action: undefined,
    },
  ];

  const holContactPhones = [
    {
      title: "Phone: +62 21 - 2270 - 8910",
      action: undefined,
    },
    {
      title: "Fax: +62 21 - 2270 - 8909",
      action: undefined,
    },
  ];

  const holContactEmails = [
    {
      title: "customer@hukumonline.com",
      action: () => openEmail("customer@hukumonline.com"),
    },
    {
      title: "redaksi@hukumonline.com",
      action: () => openEmail("redaksi@hukumonline.com"),
    },
  ];

  useEffect(() => {
    setLanguage(props.lang);
  }, [props.lang]);

  const gotoTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const scrollNav = () => {
    setTimeout(() => {
      window.scrollBy({ top: -100, behavior: "smooth" });
    }, 650);
  };

  const openEmail = (email: string) => {
    window.open(`mailto:${email}?subject=Ask Hukumonline&body=`);
  };

  return (
    <div className={styles.footer}>
      <div className={`container ${styles.footer_box}`}>
        <div className={styles.footer_content}>
          <div className={styles.left_content}>
            <img
              onClick={gotoTop}
              className={styles.hol_logo}
              alt="hukumonline logo"
              src="/assets/hukumonline_original.png"
            />
            <div className={styles.left_content_wrapper}>
              <div className={styles.hol_contact}>
                <MainFooterContactItem
                  icon={<LocationIcon />}
                  items={holContactLocation}
                />
                <MainFooterContactItem
                  icon={<PhoneIcon />}
                  items={holContactPhones}
                />
                <MainFooterContactItem
                  icon={<EnvelopIcon />}
                  items={holContactEmails}
                />
              </div>

              <div className={styles.hol_logos}>
                <a
                  href="https://web.facebook.com/hukumonlinecom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="hukumonline facebook" src="/assets/facebook.png" />
                </a>
                <a
                  href="https://www.instagram.com/hukum_online/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="hukumonline instagram"
                    src="/assets/instagram.png"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/hukumonline.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="hukumonline linkedin" src="/assets/linkedin.png" />
                </a>
                <a
                  href="https://www.youtube.com/user/hukumonlinevideo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="hukumonline youtube" src="/assets/youtube.png" />
                </a>
                <a
                  href="https://twitter.com/hukumonline/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="hukumonline twitter" src="/assets/X.png" />
                </a>
                <a
                  href="https://tiktok.com/@hukumonline/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="hukumonline twitter" src="/assets/tiktok.png" />
                </a>
              </div>
            </div>
          </div>

          <div className={styles.hol_info}>
            <div className={styles.hol_info_wrapper}>
              <MainFooterItem
                title={footerStr.pro_title[props.lang]}
                items={proList}
              />
              <MainFooterItem
                title={footerStr.solution_title[props.lang]}
                items={solutionList}
              />
              <MainFooterItem
                title={footerStr.legalInsight_title[props.lang]}
                items={legalInsightList}
              />
              <MainFooterItem
                title={footerStr.ea_title[props.lang]}
                items={eventList}
              />

              <MainFooterItem
                title={footerStr.rcs_title[props.lang]}
                items={rcsList}
              />
            </div>
            <div className={styles.footer_more_info}>
              <div className={styles.certificate}>
                <div className={styles.certificate_icon}>
                  <a>
                    <img src="/assets/ISO.svg" alt="ISO" />
                  </a>
                </div>
                <div className={styles.certificate_icon}>
                  <a>
                    <img
                      src="/assets/KAN 3.png"
                      alt="Komite Akreditasi Nasional"
                    />
                  </a>
                </div>
                <div className={styles.certificate_icon}>
                  <a>
                    <img src="/assets/UKAS.png" alt="UKAS" />
                  </a>
                </div>
                <div className={styles.certificate_icon}>
                  <a
                    href="https://pse.kominfo.go.id/tdpse-detail/5020"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/kominfo.png" alt="Kominfo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <div className={`container ${styles.copyright_box}`}>
          <Copyright />

          <div
            className={styles.copyright_text}
          >{`${getCurrentYear()} Hak Cipta Terpelihara Hukumonline.com`}</div>
        </div>
      </div>
    </div>
  );
}
