export type SolutionSection = {
  id: Number;
  attributes: SolutionAttribute;
};

export type SolutionAttribute = {
  activity_name: string;
  activity_icon: any;
  activity_icon_hover: any;
  locale: string;
  slug: string;
  seo: seo;
};

export type SectorSection = {
  id: number;
  attributes: Sectors;
};

export type Sectors = {
  id: number;
  name: string;
  sector_name: string;
  sector_description: string;
  sector_data: SectorData;
};

export type SectorData = {
  topics: number;
  subtopics: number;
  regulations: number;
  obligations: number;
};

type TotalSectorsSummary = {
  sectors: number;
  topics: number;
  subtopics: number;
  regulations: number;
  obligations: number;
};

export type ExpertDetail = {
  expert_name: string;
  expert_title: string;
  expert_description: string;
  expert_image: any;
};

export type BannerMainActivity = {
  banner_title: string;
  banner_title_highlight: string;
  banner_description: string;
  banner_image: any;
};

export type ClientsData = {
  client_image: any;
  image_title: string;
};

export type MainActivityData = {
  activity_name: string;
  activity_icon: string;
  clients: any[];
  client_title: string;
  client_title_highlight: string;
  expert: ExpertDetail;
  banner: BannerMainActivity;
};

export const customRoundedValue = (value: number): number => {
  if (value < 100) return value;
  const digitLevel = Math.floor(Math.log10(value));
  const roundFactor = Math.pow(10, digitLevel - 1);
  return Math.floor(value / roundFactor) * roundFactor;
};

export const calculateSectorTotals = (
  sectors: Sectors[]
): TotalSectorsSummary => {
  const sectorCount = sectors.length;

  const totalsNumber = sectors.reduce(
    (totals, sector) => {
      totals.topics += parseInt(String(sector.sector_data.topics), 10) || 0;
      totals.subtopics +=
        parseInt(String(sector.sector_data.subtopics), 10) || 0;
      totals.regulations +=
        parseInt(String(sector.sector_data.regulations), 10) || 0;
      totals.obligations +=
        parseInt(String(sector.sector_data.obligations), 10) || 0;
      return totals;
    },
    { topics: 0, subtopics: 0, regulations: 0, obligations: 0 }
  );

  return {
    sectors: customRoundedValue(sectorCount),
    topics: customRoundedValue(totalsNumber.topics),
    subtopics: customRoundedValue(totalsNumber.subtopics),
    regulations: customRoundedValue(totalsNumber.regulations),
    obligations: customRoundedValue(totalsNumber.obligations),
  };
};

export type seo = {
  id: number;
  metaTitle: string;
  metaDescription: string;
  keywords: string;
  metaRobots: string;
  structuredData: string;
  metaViewport: string;
  canonicalURL: string;
  metaImage: { data: seoMetaImage };
  metaSocial: metaSocial[];
};

export type metaSocial = {
  id: number;
  socialNetwork: string;
  title: string;
  description: string;
  image: { data: seoMetaImage };
};

export type seoMetaImage = {
  id: number;
  attributes: seoMetaImageDetail;
};

export type seoMetaImageDetail = {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: { thumbnail: thumbnail };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: string;
  createdAt: string;
  updatedAt: string;
};

export type thumbnail = {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  url: string;
};

export const slugMapping: { [key: string]: string } = {
  "Coal Mining": "en/coal-mining",
  "Pertambangan Batubara": "coal-mining",
  "Upstream Oil & Gas": "en/upstream-oil-and-gas",
  "Hulu Migas": "upstream-oil-and-gas",
  "Downstream Oil & Gas": "en/downstream-oil-and-gas",
  "Hilir Migas": "downstream-oil-and-gas",
  Financing: "en/financing",
  Pembiayaan: "financing",
  Insurance: "en/insurance",
  Asuransi: "insurance",
};

export const activityNameMapping: { [key: string]: string } = {
  "Hulu Migas": "Hulu Migas",
  "Upstream Oil & Gas": "Hulu Migas",
  "Hilir Migas": "Hilir Migas",
  "Downstream Oil & Gas": "Hilir Migas",
  Asuransi: "Asuransi",
  Insurance: "Asuransi",
  Pembiayaan: "Pembiayaan",
  Financing: "Pembiayaan",
  "Pertambangan Batubara": "Pertambangan Batubara",
  "Coal Mining": "Pertambangan Batubara",
};

export function getMappedActivityName(activityName: string) {
  return activityNameMapping[activityName] || activityName;
}
